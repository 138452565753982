import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

import {
    gridClass,
    contentBox,
    titleBox,
    imgBox,
    img,
    closed,
    open,
    moreArrow,
    arrowSvg,
} from "./measure-section.module.scss";
import AnimateHeight from "react-animate-height";
import Arrow from "../../assets/images/svg/expand-button.svg";

export interface ISectionRowProps {
    className?: string;
    title?: string;
    content?: string;
    image?: React.ReactElement;
}

const MeasureSection: React.FC<ISectionRowProps> = ({
    className = "",
    title = "",
    content = "",
    image,
}) => {
    const [isContentOpen, setIsContentOpen] = useState(false);

    return (
        <div
            className={`
                ${gridClass}
                ${className}
            `}
        >
            <div className={imgBox}>{image && <div className={img}>{image}</div>}</div>
            {title && <div className={titleBox}>{title}</div>}

            {content && (
                <>
                    <AnimateHeight
                        className={`${contentBox} ${isContentOpen ? "" : closed}`}
                        duration={500}
                        height={isContentOpen ? "auto" : 24}
                        onClick={() => setIsContentOpen(!isContentOpen)}
                    >
                        <ReactMarkdown
                            // @ts-ignore
                            rehypePlugins={[rehypeRaw]}
                        >
                            {content}
                        </ReactMarkdown>
                    </AnimateHeight>
                    <span
                        className={`${moreArrow} ${isContentOpen ? open : ""}`}
                        onClick={() => setIsContentOpen(!isContentOpen)}
                    >
                        <Arrow className={arrowSvg} />
                    </span>
                </>
            )}
        </div>
    );
};

export default MeasureSection;
